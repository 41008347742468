<template>
  <section
    class="border-static-info-mid rounded-lg grid grid-cols-2 gap-16 border py-8 pe-8 ps-8 md:grid-cols-[1fr_1fr_40%] md:gap-24 md:pe-24"
  >
    <VerifiedRefurbishedButton
      :subtitle="i18n(translations.refurbished)"
      :title="i18n(translations.verified)"
    />

    <div class="flex h-auto w-full items-center">
      <slot />
    </div>
    <div class="hidden md:block">
      <!-- ToDo: To be continued in PPI-5415 -->
      <div class="caption text-ellipsis">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './TrustAssuranceBanner.translations'
import VerifiedRefurbishedButton from './components/VerifiedRefurbishedButton.vue'

const i18n = useI18n()
</script>
